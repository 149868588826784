@import "../0-abstracts/";

.nav__drawer {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  min-height: max-content;
  background-color: $links;
  z-index: 15;
  $self: &;
  &-wrapper {
    @include display-flex(
      $width: 100%,
      $height: 100%,
      $justify: flex-start,
      $align: flex-end
    );
    position: absolute;
    top: 75px;
    right: 0;
    background-color: $links;
    overflow-y: auto;
    .MuiGrid-container .MuiGrid-item {
      width: 100%;
      transform: translateX(100%);
    }
    #{$self}-items {
      @include display-flex;
      #{$self}-item-box {
        @include display-flex;
        padding: 8px 0;
        #{$self}-item {
          @include font(
            $family: "sora",
            $transform: capitalize,
            $color: $white
          );
          display: block;
          text-decoration: none;
          color: $white;
          filter: drop-shadow(0.5px 0.5px 5px rgba($black, 0.1));
          &:hover,
          &:active {
            color: $yellow;
            transition: 0.3s;
          }
        }
      }
    }
  }
}

.--active-item-from-right {
  .nav__drawer-items {
    @for $i from 0 through 3 {
      $delay: unquote((0.1 * $i) + "s");
      .nav__drawer-items__grid-item:nth-of-type(#{$i + 1}) {
        animation: active-from-right 0.5s ease $delay 1 forwards;
      }
    }
  }
}

.--transition-close--left {
  animation: hide-drawer-left 0.2s ease 1 forwards !important;
}
.--transition-close--right {
  animation: hide-drawer-right 0.2s ease 1 forwards !important;
}

.active-drawer {
  animation: show-drawer 0.5s ease 1 forwards;
}

