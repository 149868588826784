@import "../1-base/";
@import "../2-components/title";
@import "../3-layout/Contact/ContactForm";

.contact {
  @include display-flex;
  width: 100%;
  min-height: max-content;
  $self: &;
  &__wrapper {
    width: 100%;
    height: 100%;
  }
}
