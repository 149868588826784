@import "./typographyLists";

.company__name {
  @include font-size($company-name);
}

.cta-row__text {
  @include font-size($row-text);
}

.cta-button {
  @include font-size($cta-button);
}

.advice-row__title {
  @include font-size($advice-row-title);
}

.advice-row__text,
.location__text {
  @include font-size($cta-button);
}

.contact-row__text {
  @include font-size($row-text);
}

.our-products__item-text {
  @include font-size($card-title);
}

.MuiButton-label {
  @include font-size($cta-button);
}

.section-title {
  @include font-size($section-title);
}

.footer-socials__title {
  @include font-size($socials-title);
}

.footer-links__title {
  @include font-size($links-title);
}

.footer-links__link {
  @include font-size($link);
}

.nav__bar-item {
  @include font-size($cta-button);
}

.nav__drawer-item {
  @include font-size($drawer-item);
}

.modal__content-title {
  @include font-size($item-text);
}
