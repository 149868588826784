@import "../0-abstracts/";

.section__title-box {
  @include display-flex;
  @include min-width(350px) {
    align-items: flex-start;
  }
  .section-title {
    @include font($color: $black, $family: "poppins", $weight: 700);
    min-width: max-content;
    &::first-letter {
      text-transform: capitalize;
    }
  }
  hr {
    width: 100%;
    border: none;
    border-bottom: 0.25px solid $dark-gray;
  }
}
