@import "./paddingLists";

.nav__wrapper {
  @include padding($nav-padding);
  max-width: 1440px;
}

.nav__drawer-wrapper {
  @include padding($drawer-wrapper-padding);
}

header .carousel {
  @include padding($carousel-padding);
}

.advice-row__title,
.advice-row__text,
.link-icon__box,
.our-products__items,
.location__map,
.location__text {
  @include padding($text-divider-padding);
}

.advice-row__text-container {
  @include padding($advice-row-content-padding);
}

.cta-row__wrapper,
.contact__wrapper,
.location__wrapper,
.advice-row__wrapper,
.footer__wrapper,
.our-products__wrapper {
  @include padding($padding);
  max-width: 1440px;
}
