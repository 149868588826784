@import "../0-abstracts/";

.nav__menu-button {
  appearance: none;
  outline: none;
  border: none !important;
  background: transparent;
  width: 30px;
  height: 30px;
  padding: unset;
  z-index: 20;
  .nav__button-icon{
    width: 100%;
    height: 100%;
    path{
      fill: $white;
    }
  }
}
