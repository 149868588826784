@import "../0-abstracts/";

.cta-button {
  background-color: $light-blue !important;
  border-radius: 40px !important;
  width: 150px;
  min-width: max-content;
  .MuiButton-label {
    padding: 4px 16px;
    @include font(
      $family: "poppins",
      $type: "" !important,
      $weight: 300 !important,
      $color: $white
    );
    filter: drop-shadow(0.1px 0.1px 1px $light-gray);
    a {
      text-decoration: none;
      color: $black;
    }
  }
  &:hover {
    background-color: $yellow !important;
    .MuiButton-label {
      color: $white;
      filter: none;
      a {
        color: $white;
        filter: none;
      }
    }
  }
}

.modal-button {
  width: 150px !important;
  background-color: $button !important;
  border-radius: 20px !important;
  &:hover {
    background-color: $button-active !important;
    .MuiButton-label {
      color: $white;
      filter: none;
    }
  }
  .MuiButton-label {
    padding: 4px 0 !important;
    width: 100%;
  }
}
