@import "../1-base/";
@import "../0-abstracts/";

.cta-row {
  @include display-flex($height:25vh);
  min-height: 300px;
  background-color: $light-gray;
  $self: &;
  &__wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    #{$self}__content {
      @include display-flex($height: 100%);
      text-align: center;
      @include min-width(1280px) {
        margin-top: unset;
      }
      #{$self}__text-box {
        @include display-flex($direction: row);
        #{$self}__text {
          @include font(
            $family: "poppins",
            $transform: uppercase,
            $weight: 700,
            $color: $black
          );
          span{
            padding: 0px 8px;
            line-height: inherit;
            .contact-list-icon{
              color: $gray;
              min-width: 23px;
              min-height: 23px;
              height: 45px;
              width: 45px;
              vertical-align: middle
            } 
          }
        }
      }
      .button-box {
        @include display-flex;
        position: relative;
        top: 24px;
      }
    }
  }
}